import React, { useState } from 'react'
import moment from 'moment-timezone'
import { Container } from 'react-grid-system'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Spinner, Dialog, Classes, Button } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import Schedule from './schedule'
import TopBar from '../layout/top-bar/top-bar'
import Footer from '../layout/footer/footer'
import Login from '../login/login'

import { patientsApi } from '../../api'
import { serverErrorToast, errorToast, successToast } from '../layout/toaster/toaster'
import { packScheduleThrifty28days, packScheduleJones7days } from '../../config/defaultSchedules'

import css from './patient.module.css'

function Patient () {
  const [patient, setPatient] = useState()
  const [isStartDateDialogOpen, setStartDateDialogOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [rxId, setRxId] = useState('')
  const [clientId, setClientId] = useState('')

  const fetchData = async () => {
    try {
      if (!rxId || !clientId) {
        return errorToast('Pharmacy ID and Clinet ID can not be empty')
      }

      setPatient(false)
      const { data } = await patientsApi.get(`rx/${rxId}/${clientId}`)
      if (!data.subscriptionStartDate) {
        setStartDateDialogOpen(true)
      }

      // Support more types of packs and default to 7 days if nothing is found
      if ((!data.packType || data.packType === 'jones7days') && !data.packSchedule?.weeks) {
        data.packSchedule = packScheduleJones7days
      } else if ((!data.packType || data.packType === 'us_7days') && !data.packSchedule?.weeks) {
        data.packSchedule = packScheduleJones7days
      } else if ((!data.packType || data.packType === 'ca_7days') && !data.packSchedule?.weeks) {
        data.packSchedule = packScheduleJones7days
      } else if ((!data.packType || data.packType === 'us_dispill') && !data.packSchedule?.weeks) {
        data.packSchedule = packScheduleJones7days
      } else if (data.packType === 'thrifty28days' && !data.packSchedule?.packs) {
        data.packSchedule = packScheduleThrifty28days
      } else {
        data.packSchedule = packScheduleJones7days
      }
      setPatient(data)
    } catch (err) {
      setPatient(null)
      serverErrorToast(err, true)
    }
  }

  const saveAssignParticipant = async (body) => {
    const errorText = 'Failed to assign participant with StickerId:' + body.stickerId + ', recordId:' + body.recordId + '.  Please ensure the pack is activated and assigned to the Sticker Id.  Please contact CuePath for further assistance.'
    try {
      setPatient(false)

      const bodyData = {
        stickerId: body.stickerId,
        recordId: body.recordId,
        event: body.event,
        patientId: body.patientId,
        repeatInstanceId: body.repeatInstanceId
      }

      const { data } = await patientsApi.post('rx/setParticipantInfo', bodyData)
      if (data) {
        const successText = 'Successfuly assigned participant with StickerId:' + body.stickerId + ' RecordId:' + bodyData.recordId + ' EventName:' + bodyData.event + ' repeatInstanceId:' + bodyData.repeatInstanceId
        alert(successText)
        successToast(
          successText
        )
      } else {
        alert(errorText)
        errorToast(errorText)
      }
    } catch (err) {
      alert(errorText)
      errorToast(errorText)
      setPatient(null)
    } finally {
      setPatient(body.patient)
    }
  }

  const savePatient = async (body) => {
    try {
      setPatient(false)
      const { data } = await patientsApi.patch(`rx/${rxId}/${clientId}`, body)
      setPatient(data)
      successToast(
        'The schedule was successfully updated. If you made changes to current week, it will be applied tomorrow.'
      )
    } catch (err) {
      setPatient(null)
      serverErrorToast(err)
    }
  }

  return (
    <>
      <TopBar
        patient={patient}
        rxId={rxId}
        setRxId={setRxId}
        clientId={clientId}
        setClientId={setClientId}
        fetchData={fetchData}
      />
      <Container style={{ maxWidth: 1200 }} className={css.content}>
        <Switch>
          {patient === false && <Spinner />}
          <Route path="/login">
            {patient && <Redirect to="/" />}
            <Login rxId={rxId} setRxId={setRxId} clientId={clientId} setClientId={setClientId} fetchData={fetchData} />
          </Route>
          <Route path="/">
            {patient ? <Schedule patient={patient} savePatient={savePatient} saveAssignParticipant={saveAssignParticipant}/> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </Container>
      <Footer />
      <Dialog
        icon="info-sign"
        title="Prescription Start Date"
        isOpen={isStartDateDialogOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            <strong>
              Please set prescription start date for this patient, specifying when the first pack should be started.
            </strong>
          </p>
          <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
            <DatePicker
              className={Classes.ELEVATION_1}
              onChange={(v) => setStartDate(v)}
              maxDate={moment().add(12, 'month').toDate()}
            />
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                savePatient({ ...patient, subscriptionStartDate: startDate })
                setStartDateDialogOpen(false)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Patient
